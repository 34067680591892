import blue from '@material-ui/core/colors/blue';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import React from 'react';
import ReactDOM from 'react-dom';


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {LicenseManager} from "ag-grid-enterprise";

const agGridKey = 'CompanyName=ApeTools,LicensedApplication=ApeTools,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017186,ExpiryDate=8_July_2022_[v2]_MTY1NzIzNDgwMDAwMA==2e24004b509ca379ef90100c7a6aa04d'

LicenseManager.setLicenseKey(agGridKey);


const theme = createMuiTheme({
    palette: {
        type: "dark",
        background: {
            default: '#2d3436'
        },
        primary: blue,
        secondary: pink
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
