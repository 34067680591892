import React from 'react';
import { Button, Grid } from "@material-ui/core";
import Link from '@material-ui/core/Link';

import './landing.css';

const LandingPage = props => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{textAlign: 'center'}}
            className={'landing-main'}
        >
            <Grid item xs={10} sm={6}>
                <h1 style={{fontSize: '4em'}}>
                    Meet the next generation of BSC Trading Tools.
                </h1>
                <Button variant="outlined" className={'learn-more-button'}>
                    <Link href="https://t.me/apetools/44" target="_blank" color='inherit' style={{}}>
                        Learn More
                    </Link>
                </Button>
                <Button onClick={props.login} variant="contained" color="primary">
                    Connect Wallet
                </Button>
            </Grid>
        </Grid>
    );
}

export default LandingPage;