import React from 'react';

const LoadingText = () => {
  const messages = ['Creating the Universe', 'Baking Apple Pie', 'Falcon Punching', 'Loading a Pump', 'Fetching Data']
  const [messageIndex, setMessageIndex] = React.useState(0);

  React.useEffect(() => {
    // Move on to the next message every `n` milliseconds
    let timeout;
    if (messageIndex < messages.length - 1) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 1500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [messageIndex]);

  return <div>{messages[messageIndex]}...</div>;
};

export default LoadingText;
