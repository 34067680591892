import React, {useState} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { AllModules } from 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css'
import SimpleSnackbar from "./Snackbar";

const BlacklistGrid = props => {
    const [whitelistSnackbarMessage, setWhitelistSnackbarMessage] = useState('');
    const [lastWhitelistedToken, setLastWhitelistedToken] = useState('');

    // TODO get this data passed from parent
    const getRowData = () => {
        return props.blacklistedTokens.map((row) => {
            return {
                'Token': row.Token,
                'Address': row.Address,
                'In': row.In,
            }
        })
    }

    const onFirstDataRendered = (params) => {
        let allColumnIds = [];
        params.columnApi.getAllColumns().forEach(function(column) {
            allColumnIds.push(column.colId);
        });

        params.columnApi.autoSizeColumns(allColumnIds);
    };

    const getContextMenuItems = (params) => {
        const result = [
            {
                name: 'Remove Token from Blacklist',
                action: function () {
                    const tokenAddressToWhitelist = params.node.data.Address
                    setWhitelistSnackbarMessage(
                        {
                            'message': `Removed "${params.node.data['Token']} From Blacklist"`,
                            'date': new Date()
                        }
                    );
                    props.removeTokenFromBlacklist(tokenAddressToWhitelist)
                    setLastWhitelistedToken(tokenAddressToWhitelist)
                },
            },
            'copy',
        ];
        return result;
    };

    const numberFormatter = params => {
        return params.value !== null ? params.value.toFixed(2) : ''
    }

    const defaultColDef = {
        sortable: true,
        enableCellChangeFlash: true,
    };

    return (
        <div>
            <div className={"ag-theme-balham-dark"} style={{ height: '35vh', width: '100%'}}>
                <div style={{height: '100%', width: '100%'}}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        modules={AllModules}
                        rowData={getRowData()}
                        onFirstDataRendered={onFirstDataRendered}
                        getContextMenuItems={getContextMenuItems}
                    >
                        <AgGridColumn
                            field="Token"
                            maxWidth={175}
                        />
                        <AgGridColumn
                            field="In"
                            valueFormatter={numberFormatter}
                            type="rightAligned"
                        />
                        <AgGridColumn
                            field="Address"
                            maxWidth={1000}
                            flex={1}
                        />
                    </AgGridReact>
                </div>
            </div>
            {  whitelistSnackbarMessage
                ? <SimpleSnackbar
                    key={whitelistSnackbarMessage.date}
                    message={whitelistSnackbarMessage.message}
                    undo={() => props.addTokenToBlacklist(lastWhitelistedToken)}
                />
                : null
            }
        </div>
    )
}

export default BlacklistGrid;