import React from 'react'

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingText from './LoadingText'

const Loading = () => {
    return (
        <Box 
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection='column'
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress className='portfolio-progress'/>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{marginTop: '2rem'}}
            >
                <LoadingText/>
            </Box>
        </Box>
    )
}


export default Loading;