import { Button } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import ReactGA from 'react-ga';

import logoImage from './images/main-logo-icon.png';
import logoText from './images/main-logo-text.png';

import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import Dashboard from "./Dashboard";
import LandingPage from "./LandingPage"
import WalletModal from './WalletModal'
import Link from '@material-ui/core/Link';
import TelegramIcon from '@material-ui/icons/Telegram';
import TokenDashboard from './TokenDashboard';


const trackingId = "UA-201277478-1";
ReactGA.initialize(trackingId);


const App = () => {
    const [darkMode, setDarkMode] = useState(true)
    const [walletAddress, setWalletAddress] = useState(null)

    // Fired on every route change
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [window.location]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    const login = async() => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];
        console.log('walletAddress: ', walletAddress)

        // TODO save to cookie
        setWalletAddress(walletAddress)
    }

    const logout = () => {
        // TODO save to cookie
        setWalletAddress(null)
    }

    return (
        <div className='main-container'>
            <header>
                <a href="https://www.apetools.app" style={{textDecoration: 'none'}} target="_blank" rel='noreferrer'>
                    <img className='logo-image' src={logoImage} alt="ApeTools Logo Icon " />
                    <img className='logo-text' src={logoText} alt="ApeTools Logo Text" />
                </a>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link href="https://t.me/apetools" target="_blank" color='inherit' style={{marginRight: '1rem', display: 'flex', alignItems: 'center'}}>
                        <TelegramIcon />
                    </Link>
                    {walletAddress
                        ? <WalletModal walletAddress={walletAddress} />
                        : <Button variant="contained" color="primary" onClick={ !walletAddress ? login : logout}>Connect Wallet</Button>
                    }
                </div>
            </header>
            <Router>
                <Switch>
                    <Route path={'/portfolio/:portfolioId'}
                           render={
                               routeProps => <Dashboard
                                   darkMode={darkMode}
                                   payload={{
                                       'id': Number(routeProps.match.params.portfolioId)
                                   }} />
                           }
                    />
                    <Route path={'/wallet/:walletAddress'}
                           render={
                               routeProps => <Dashboard
                                   darkMode={darkMode}
                                   payload={{
                                       'walletAddress': routeProps.match.params.walletAddress
                                   }} />
                           }
                    />
                    <Route path={'/token/:tokenAddress'}
                           render={
                               routeProps => <TokenDashboard
                                   tokenAddress={routeProps.match.params.tokenAddress} 
                                   />
                           }
                    />
                    <Route path={'/'}>
                        {walletAddress
                            ? <Dashboard darkMode={darkMode} payload={{'walletAddress': walletAddress}} />
                            : <LandingPage login={login} />
                        }
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default App;