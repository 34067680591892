import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      }
    },
    textfield: {
        // maxWidth: '65vw',
    }
  }));


const WalletSearchbar = () => {
    const history = useHistory();
    const [walletAddress, setWalletAddress] = useState('')
    const classes = useStyles();

    const handleChange = (event) => {
        setWalletAddress(event.target.value);
    };

    const handleOnSubmit = () => {
        history.push(`/wallet/${walletAddress}`);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleOnSubmit} style={{display: 'flex', justifyContent: 'center'}}>
            <Grid container spacing={1} style={{display: 'flex', justifyContent: 'space-around', alignItems: 'space-around', width: '95vw'}}>
                <Grid item xs={12} sm={6} md={4} spacing={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField 
                        className={classes.textfield}
                        id="outlined-basic" 
                        label="Enter Wallet Address" 
                        variant="outlined" 
                        value={walletAddress}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} spacing={1} style={{display: 'flex', justifyContent: 'center', marginTop: '.75rem', marginBottom: '1.5rem'}}>
                    <Button type="submit" variant="contained" color='primary' disabled={walletAddress.length !== 42}>
                        View Portfolio
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default WalletSearchbar;