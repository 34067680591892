import { Button, IconButton, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import BlacklistGrid from './BlacklistGrid'

import './Dialog.css';
import './index.css';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const BlacklistModal = props => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Button onClick={handleOpen} variant="outlined">
                Manage Blacklist
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >               
                <DialogTitle id="transition-modal-title" onClose={handleClose}>
                    Manage Blacklist
                </DialogTitle>
                <DialogContent dividers>
                    <BlacklistGrid
                        addTokenToBlacklist={props.addTokenToBlacklist}
                        removeTokenFromBlacklist={props.removeTokenFromBlacklist}
                        blacklistedTokens={props.blacklistedTokens}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default BlacklistModal;